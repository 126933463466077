import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../../../components/287634/layout/layout';
import { CompanyContext } from '../../../context/company.context';
import { LangContext } from '../../../context/lang.context';
import Button from '../../../components/287634/button/button';
import { H1, H2, P } from '../../../components/287634/typography';
import { returnWhatsapp } from '../../../utils/browser';

const AddedPaid = () => {
  const { getPublicURL } = useContext(CompanyContext);
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          RETURN_WHATSAPP
        }
      }
    }
  `);

  return (
    <Layout>
      <section className="w-full text-center text-primary">
        <H2 className="mt-[60px] mb-10">Recargar saldo</H2>
        <img
          className="mx-auto w-2/3"
          alt="Success"
          src={getPublicURL(`/theme/assets/images/feedback/generic-success.svg`)}
        />
      </section>
      <section className="px-5 text-center text-primary">
        <H1 className="mx-5 mb-10">¡Recarga realizada con éxito!</H1>
        <P className="mb-10">
          Ya puedes enviar dinero por WhatsApp. Regresa y sigue operando.
        </P>
      </section>
      <Button type="button" onClick={() => returnWhatsapp()}>
        {t(data).RETURN_WHATSAPP}
      </Button>
    </Layout>
  );
};

export default AddedPaid;
